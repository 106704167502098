<template>
  <PageCard
    :pageName="
      partnerSite ? 'The Ethos Performance Intelligence' : 'FI Analytics'
    "
    :iconClass="partnerSite ? [] : ['cuis-chart-line', 'card-icon-font']"
  >
    <template slot="page_content">
      <div v-if="partnerSite">
        <div class="row">
          <div class="col-sm-12">
            <p class="px-1">
              Launch the tools below to generate instant insights and drive the
              performance of your institution. Leverage data-driven consultative
              assessments and industry-leading peer analytics to outperform your
              competition.
            </p>
          </div>
        </div>

        <b-row class="about-row">
          <b-col cols="4" class="px-3">
            <b-card
              class="std-border mb-0"
              header-bg-variant="success"
              header-text-variant="white"
              footer-class="d-none"
            >
              <b-card-body class="p-0">
                <div>
                  <span><b>FIN Advisor</b></span>
                  : Instantly generate insights with an action plan to expertly
                  manage your institution.
                </div>

                <router-link
                  tag="button"
                  :to="{ path: '/analytics/advisor/select_institution' }"
                  class="btn btn-secondary fw-btn my-3 p-1 br-1-em"
                  >Launch FIN Advisor</router-link
                >
                <b-card-img
                  src="../../../../static/img/ethos/advisor.png"
                  class="rounded-0 my-2"
                />
              </b-card-body>
            </b-card>
          </b-col>
          <b-col cols="4" class="px-1">
            <b-card
              class="std-border mb-0"
              header-bg-variant="success"
              header-text-variant="white"
            >
              <b-card-body class="p-0">
                <div>
                  <span><b>FIN Reporter</b></span>
                  : Instantly generate presentations and custom reports for any
                  meeting scenario.
                </div>

                <router-link
                  tag="button"
                  :to="{ path: '/analytics/reporter/select_institution' }"
                  class="btn btn-secondary fw-btn my-3 p-1 br-1-em"
                  >Launch FIN Reporter</router-link
                >
                <b-card-img
                  src="../../../../static/img/ethos/reporter.png"
                  class="rounded-0 my-2"
                />
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </div>

      <div v-else>
        <b-row class="about-row">
          <b-col cols="4" class="px-1">
            <b-card
              class="std-border mb-0"
              header-bg-variant="success"
              header-text-variant="white"
            >
              <div slot="header">FIN Advisor</div>
              <b-card-body class="p-0">
                <div>
                  Instantly generate a complete consultative assessment of any
                  U.S. financial institution’s profit, growth, risk, and
                  customer offering generating primary opportunity themes,
                  specific strengths/weaknesses and even custom solution
                  category recommendations. As the name suggests, FIN Advisor
                  instantly positions users to render advice and the analytics
                  serve as the foundation for account or strategic planning.
                </div>
                <b-card-img
                  src="../../../../static/img/advisor.png"
                  class="rounded-0 my-2"
                />
                <div class="mb-2">
                  Make the vendor-to-advisor transition by leveraging FIN
                  Advisor’s powerful planning foundation.
                </div>
                <div class="about-footer">
                  <div class="about-footer-content">
                    <router-link
                      tag="button"
                      :to="{ path: '/analytics/advisor/select_institution' }"
                      class="btn btn-secondary"
                      >View now</router-link
                    >
                    <a
                      href="mailto:info@fi-navigator.com"
                      class="btn btn-secondary"
                      >More Info</a
                    >
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col cols="4" class="px-1">
            <b-card
              class="std-border mb-0"
              header-bg-variant="success"
              header-text-variant="white"
            >
              <div slot="header">FIN Reporter</div>
              <b-card-body class="p-0">
                <div>
                  FIN Reporter delivers complete control to vendor users on
                  reports, metric, peers, time spans and graphics to create
                  custom content for any meeting scenario. Transform
                  unprecedented data into presentations that garner C-suite
                  attention and fit any meeting time frame. Compare and contrast
                  any institution's performance against peers or stand-alone
                  trends, then zoom in on specific metrics that matter most
                  during the conversation.
                </div>
                <b-card-img
                  src="../../../../static/img/report.png"
                  class="rounded-0 my-2"
                />
                <div class="mb-2">
                  Engage FI clients and prospects with differentiating meeting
                  content that resonates and creates the impetus for action.
                </div>
                <div class="about-footer">
                  <div class="about-footer-content">
                    <router-link
                      tag="button"
                      :to="{ path: '/analytics/reporter/select_institution' }"
                      class="btn btn-secondary"
                      >View now</router-link
                    >
                    <a
                      href="mailto:info@fi-navigator.com"
                      class="btn btn-secondary"
                      >More Info</a
                    >
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col cols="4" class="px-1">
            <b-card
              class="std-border mb-0"
              header-bg-variant="success"
              header-text-variant="white"
            >
              <div slot="header">FIN Profile</div>
              <b-card-body class="p-0">
                <div>
                  Rapidly assemble the high-level facts on any institution's
                  profit, growth, risk, and offerings with FI Profile. The
                  analytics condense comprehensive institution analysis into
                  fast facts on any institution’s firmographics, performance and
                  known vendor-product relationships to fit any call or meeting
                  context.
                </div>
                <b-card-img
                  src="../../../../static/img/profile.png"
                  class="rounded-0 my-2"
                />
                <div class="mb-2">
                  Instantly get up to speed on any financial institution to
                  engage intelligently on your next call.
                </div>
                <div class="about-footer">
                  <div class="about-footer-content">
                    <router-link
                      tag="button"
                      :to="{
                        path: '/analytics/fin_profile/select_institution'
                      }"
                      class="btn btn-secondary"
                      >View now</router-link
                    >
                    <a
                      href="mailto:info@fi-navigator.com"
                      class="btn btn-secondary"
                      >More Info</a
                    >
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </template>
  </PageCard>
</template>

<script>
// global
import { mapState } from 'vuex'
// layout
import PageCard from '@/modules/core/components/layouts/PageCard'

export default {
  name: 'AboutAnalytics',
  components: {
    PageCard
  },
  created() {
    if (!this.partnerSite) {
      document.title = 'FI Analytics'
    }
  },
  computed: {
    ...mapState({
      partnerSite: state => state.partnerSite
    })
  }
}
</script>

<style lang="scss" scoped></style>
